
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import { countryNoComList } from "@/utils";
import { saveAs } from "@/api/request/base";
import Pagination from "@/comp/Pagination/index.vue";
import { getBloggerCmpanionList, getBloggerCompanionExport } from "@/api/request/blogger";

//组件
@Component({
  name: "Companion",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = []; //列表
  private total: number = 0; //总数
  private countryList: any[] = []; //归属国家列表
  private listLoading: boolean = false; //列表加载

  //请求数据
  private listQuery: any = {
    //页码
    page: 1,
    page_size: 20,

    //临时数据
    time: [],
    user_id_str: "",
    blogger_id_str: "",

    //临时数据
    user_id: 0, //用户ID
    country: "", //归属国家
    blogger_id: 0, //博主ID
    nick_name: "", //用户昵称
    blogger_nick_name: "", //博主昵称

    //状态数据
    action: -1, // 通信行为：-1-全部，0-无，1-语音，2-视频，3-私信
    is_expired: -1, // 是否过期：-1-全部，0-未过期，1-已过期
    award_status: -1, // 奖励状态：-1-全部，0-未发放，1-未领取，2-已领取

    //时间数据
    end_time: "", // 绑定结束时间
    start_time: "", // 绑定开始时间
  };

  //通信行为
  private actionList: any[] = [
    { action: -1, name: "全部通信行为" },
    { action: 0, name: "无" },
    { action: 1, name: "语音" },
    { action: 2, name: "视频" },
    { action: 3, name: "私信" },
  ];

  //过期状态
  private expiredList: any[] = [
    { status: -1, name: "全部过期状态" },
    { status: 0, name: "未过期" },
    { status: 1, name: "已过期" },
  ];

  //过期状态
  private awardStatusList: any[] = [
    { status: -1, name: "全部奖励状态" },
    { status: 0, name: "未发放" },
    { status: 1, name: "未领取" },
    { status: 1, name: "已领取" },
  ];

  //创建调用
  created() {
    //数据赋值
    this.countryList = countryNoComList;

    //获取列表
    this.getList();
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.user_id = Number(this.listQuery.user_id_str);
    this.listQuery.blogger_id = Number(this.listQuery.blogger_id_str);

    //时间赋值
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.end_time = `${this.listQuery.time[1]} 23:59:59`;
      this.listQuery.start_time = `${this.listQuery.time[0]} 00:00:00`;
    } else {
      this.listQuery.end_time = "";
      this.listQuery.start_time = "";
    }

    //获取数据
    const { data } = await getBloggerCmpanionList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleSearch(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取列表
    this.getList();
  }

  //处理导出
  private async handleExport() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.user_id = Number(this.listQuery.user_id_str);
    this.listQuery.blogger_id = Number(this.listQuery.blogger_id_str);

    //时间赋值
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.end_time = `${this.listQuery.time[1]} 23:59:59`;
      this.listQuery.start_time = `${this.listQuery.time[0]} 00:00:00`;
    } else {
      this.listQuery.end_time = "";
      this.listQuery.start_time = "";
    }

    //获取数据
    const { data } = await getBloggerCompanionExport(this.listQuery);

    //保存数据
    saveAs(data, "缘分伴侣列表");

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //获取归属国家名称
  private getCountryName(country: string): string {
    //没有国家属性
    if (country == "") {
      return "-";
    }

    //判断国家
    for (var i: number = 0; i < this.countryList.length; i++) {
      if (country == this.countryList[i].country) {
        return this.countryList[i].name;
      }
    }

    //反馈空
    return "";
  }
}
